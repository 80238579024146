.chart {
    padding-top: 0px;
    padding-left: 0px;
    height: 90vh;
   
  }

.title{
  color: white;
}

.cruxy-dark{
  color: var(--dark-text);
}
