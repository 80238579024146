.cockpit {
    display: flex;
    overflow: hidden;
}

.appBar{
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1300;
    flex: 0 0 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    
}

.appBarFull {
    background-color: var(--colour-dark5);
    width: 100%;
    transition: width 125ms, margin 125ms;
}

.appBarShifted {
    margin-left: 250px;
    width: calc(100% - 250px);
    background-color: var(--colour-dark5);
    transition: width 195ms, margin 195ms;
}

.appBarShifted .opener {
    display: none;
}

.appBar .headerRightGroup{
    margin-left: auto;
}

.opener{
    display: flex;
}


.sideBar{
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    overflow: hidden;
    flex-direction: column;  
    background-color: var(--colour-dark5);
}

.sideBar.full {
    width: 250px;   
    transition: width 195ms, margin 195ms;
}

.logo {
    height: 37px;
    pointer-events: none;
  }

.sideBar.minimised {
    width: 95px;
    transition: width 125ms, margin 125ms;
}

.sideBarHeader{
    display: flex;
    flex: 0 0 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    max-height: 50px;
    align-items: center;
    justify-content: space-between;
    max-width: 250px;
}

.menu{
    display: flex;
    flex: 1;
    overflow-y: auto;
}
.headingItem{
    padding: 12px;
}
.cockpit-content{
    height: 100vh; 
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: scroll;
}


.app{
    display: flex;
    flex-grow: 1;
    flex-direction: row;
   
}
.appBarSpacer{
    min-height: 50px;
}