.admin {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    padding-left: 25px;  
}


.adminlink{
    font-weight: bold;
}

.user-overlay{
    margin: auto;
    position: absolute;
    top: 150px; left: 150px;
    bottom: 150px; right: 150px;
    background-color: #000;
    color: #FFF;
    display: flex;
    flex-direction: column;
}

.user-overlay-row{
   display: flex;
   flex-direction: row;
   padding-top: 5px;
   padding-bottom: 5px;
}

.user-overlay-row-item{
    padding-left: 0px;
    padding-right: 5px;
 }

.user-overlay-group{
    padding-top: 5px;
    padding-bottom: 5px;
}

.user-overlay-close{
    padding-top: 10px;
    padding-right: 10px;
    height: 80%;
    display: flex;
    align-items: flex-end;
 
}