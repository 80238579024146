

.HeaderBar{
    background-color: var(--colour-dark5) !important;
  }
  

  .header-link:any-link{
    color: inherit;
    text-decoration: none;
  }
  .header-link:any-link:hover{
    color: inherit;
    text-decoration: none;
  }




  @media (max-width: 768px) {
    .NavBar .menuOpener {   
      display: flex;
    }
   
  }