:root {
  
  --colour-dark5: #011A27;
  --colour-white: 	#FFFFFF;
  --dark-text: #011a27;
  --gold-text: #d4bd6d;
 
}

body {
  font-family: Poppins !important;
}