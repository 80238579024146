.navList {
    margin: 0;
    list-style-type: none;
    margin: 0;
    border-radius: 3px;
    padding: 5px;
    list-style: none;
    text-align: left;
  }

  .navList li:hover{ 
    background-color: var( --colour-dark4); 
    cursor: pointer;
  }

  .chartmenu{
    padding-top: 0px;
    border: none !important;
  }


  .icon{
      color: white;
  }

  .menuheading{
    display: flex;
    flex-direction: row;
    padding-top: 5px;
  }

  .menuheading .icon{
    padding: 5px;

  }
  .menuheading .title{
   
    align-self: center;

  }

  .sidebar.minimised .menuheading .title{
    display: none;
  }

  .menuitem {
    padding-left: 30px;
    padding-top: 2px;
  }
 
