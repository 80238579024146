.dashboard{
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  padding-top: 25px;
}


.dashboard-centre {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 40px;
  padding-left:40px;
  padding-bottom: 5px;

  
}

.dashboard-column {
  display: flex;
  flex-direction: column;
}


.dashboard-heading {
  color: var(--colour-dark5);
  font-size: 24px; 
  padding-top: 10px;
  padding-bottom: 10px;
  
}

.dashboard-chart {
  padding-right: 10px;
  padding-left: 10px;
  height: 80vh;
}


.cockpit-card-row{
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  text-align: center;
}

.favourite-card{
  margin: 15px 20px 0 20px;
  width: 170px; 
  height: 140px;
  text-align: center;
}

.favourite-card-image{
  margin-left: auto;
  margin-right: auto;
  width: 130px;
  height: 100px;
  display: block;
}

.favourite-title {
  color: var(--colour-dark5);
  font-size: 12px;
  text-align: center;
  width: 170px;
  margin: 15px 20px 5px 20px;
}

.cockpit-card-heading{
  flex-direction: row;
  flex-grow: 1;
}

.cockpit-heading{
  color: var(--colour-dark5);
}

.cruxy-gold{
  color: var(--gold-text);
}

.cruxy-dark{
  color: var(--dark-text);
}

.login-area {
  padding-top: 50px;
}