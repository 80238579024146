

.title {
  white-space: nowrap;
}

.navList__item {
    display: flex;
      flex-direction: row;
      align-items: flex-start;
      border-radius: 2px;
      padding: 5px 7px;
      text-decoration: none;
      line-height: 20px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -webkit-any-link: none;
      user-select: none;
  }
  
  .navList__item-link-icon{
    margin-right: 7px;
    margin-top: 2px;
  }
  
  .navList__item-link:any-link{
    color: inherit;
    text-decoration: none;
  }
  .navList__item-link:any-link:hover{
    color: inherit;
    text-decoration: none;
  }

  .sidebar.minimised .navList__item-link-text{
    display: none;
  }